import(/* webpackMode: "eager" */ "/app/components/Avatar/User/UserAvatar.module.scss");
;
import(/* webpackMode: "eager" */ "/app/components/Divider/Divider.module.scss");
;
import(/* webpackMode: "eager" */ "/app/components/Button/Button.module.scss");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/(user)/layout.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/Tab/Tab.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/Typography/Typography.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/features/Footer/Footer.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Button/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
